export default {
  'title': 'Mes avantages',
  'my-promocodes': {
    title: 'Mes codes promos',
    text: 'Retrouver toutes vos réductions et bon d’achats disponibles.',
  },
  'referral': {
    'title': 'Parrainage',
    'text': 'Devenez ambassadeur du goût, parrainez vos proches et gagnez des récompenses !',
    'referrer-section': {
      'title': 'Un bon plan, ça se partage !',
      'share-code-description':
        'Vous avez aimé nos délicieux produits frais ? Pourquoi ne pas en parler autour de vous ?<br/>Vous aussi, devenez ambassadeur du goût, parrainez vos proches et gagnez des récompenses !',
      'your-referral-code-is': 'Votre code parrain est :',
    },
  },
  'referee': {
    'title': 'Faites-vous parrainer !',
    'description':
      'Vous êtes bien parrainés, retrouvez vos récompenses quand vous passez commande. Attendez de recevoir votre première commande pour parrainer vos amis, votre famille ou vos voisins :)',
    'rewards':
      'Demandez à un proche de vous parrainer et gagnez des récompenses !<br/>Ajoutez un code parrain avant de passer votre première commande.',
    'my-referral-code': 'Mon code parrain',
    'add-my-code': "J'ajoute mon code",
    'too-early': {
      'to-be-referral': 'Il est trop tôt pour être parrain !',
      'description':
        'Attendez de recevoir votre première commande pour parrainer vos amis, votre famille ou vos voisins :)',
    },
    'input-placeholder': '%{prefix}NOM DE FAMILLE',
    'bottom-title': 'Un bon plan, ça se partage !',
    'receive-your-gift': 'Recevez votre première commande pour devenir parrain.',
  },
  'coupons': {
    'title': 'Mes codes promos',
    'subtitle': 'Votre Fidélité récompensée !',
    'text-1':
      'Retrouvez ici toutes vos réductions récoltées grâce à votre fidélité. Plus vous commandez sur votre compte, plus nous semons des cadeaux…',
    'text-2-1': 'Pensez bien à ',
    'text-2-2': ' vous abonner aux communications ici',
    'text-2-3': ' pour que nous vous envoyons encore plus de petites attentions.',
    'ongoing-coupons': 'À utiliser (%{count})',
    'expired-coupons': 'Utilisés (%{count})',
    'reward': {
      VALUE: '%{count}€ offert',
      VALUE_plural: '%{count}€ offerts',
      FREE_SHIPPING: 'Livraison offerte',
      FREE_ARTICLE: 'Cadeau offert',
      PERCENT: '%{count}% offert',
      PERCENT_plural: '%{count}% offerts',
    },
    'confirmation-modal': {
      conditions: 'Cette offre est valable:',
      activation:
        'Vos coupons sont activables lors de la finalisation de votre commande, juste avant le paiement.',
    },
    'no-coupon': 'Aucun code promo',
  },
};
